.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  
  .product-card:hover {
    transform: translateY(-5px); /* Apply a subtle hover effect */
    transition: transform 0.3s ease-in-out;
  }
  