@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px); /* Start from 20px below and move upwards */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.5s ease-out;
  }
  